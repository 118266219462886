<template>
    <el-dialog :title="title" :visible.sync="editor.visible" @open="open"
            :close-on-click-modal="false" destroy-on-close center append-to-body width="1000px">
        <el-form ref="form" :model="form" :rules="rules" class="x-edit">
            <el-form-item label="入库单id" prop="ruKuDanId" >
                <el-input v-model="form.ruKuDanId" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="入库类型" prop="ruKuLX" >
                <el-input v-model="form.ruKuLX" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="入库时间" prop="ruKuSJ">
                <el-date-picker v-model="form.ruKuSJ" size="small" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"/>
            </el-form-item>
            <el-form-item label="商品名称" prop="shangPinMC" >
                <el-input v-model="form.shangPinMC" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="商品id" prop="shangPinId" >
                <el-input v-model="form.shangPinId" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="商品货号" prop="shangPinHuoHao" >
                <el-input v-model="form.shangPinHuoHao" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="商品条码" prop="shangPinTiaoMa" >
                <el-input v-model="form.shangPinTiaoMa" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="商品进价" prop="shangPinJinJia" >
                <el-input v-model="form.shangPinJinJia" size="small" maxlength="18" show-word-limit/>
            </el-form-item>
            <el-form-item label="入库数量" prop="ruKuSL" >
                <el-input v-model="form.ruKuSL" size="small" maxlength="18" show-word-limit/>
            </el-form-item>
            <el-form-item label="入库金额" prop="ruKuJinE" >
                <el-input v-model="form.ruKuJinE" size="small" maxlength="18" show-word-limit/>
            </el-form-item>
            <el-form-item label="供货商" prop="gongHuoShang" >
                <el-input v-model="form.gongHuoShang" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="供货商名称" prop="gongHuoShangMC" >
                <el-input v-model="form.gongHuoShangMC" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="商品单位" prop="shangPinDW" >
                <el-input v-model="form.shangPinDW" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="生产日期" prop="shengChanRQ">
                <el-date-picker v-model="form.shengChanRQ" size="small" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"/>
            </el-form-item>
            <el-form-item label="保质期（天）" prop="baoZhiQiTian" >
                <el-input v-model="form.baoZhiQiTian" size="small" maxlength="" show-word-limit/>
            </el-form-item>
            <el-form-item label="过期日期" prop="guoQiRQ">
                <el-date-picker v-model="form.guoQiRQ" size="small" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"/>
            </el-form-item>
            <el-form-item label="入库仓库id" prop="ruKuCangKuId" >
                <el-input v-model="form.ruKuCangKuId" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="是否多规格" prop="shiFuDuoGuiGe" >
                <el-input v-model="form.shiFuDuoGuiGe" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="规格货号" prop="guiGeHuoHao" >
                <el-input v-model="form.guiGeHuoHao" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="规格条码" prop="guiGeTiaoMa" >
                <el-input v-model="form.guiGeTiaoMa" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="规格商品进价" prop="guiGeShangPinJinJia" >
                <el-input v-model="form.guiGeShangPinJinJia" size="small" maxlength="18" show-word-limit/>
            </el-form-item>
            <el-form-item label="是否删除" prop="shiFuShanChu" >
                <el-input v-model="form.shiFuShanChu" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="save" icon="el-icon-document-add">保 存</el-button>
            <el-button @click="cancel" icon="el-icon-switch-button">取 消</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {add, update, getUpdate} from "@/service/kcgl/RuKuJiLu";
    import XEditBase from "@/components/x/XEditBase";
    import {ruleBuilder} from '@/util/validate';

    export default {
        mixins: [XEditBase],
        beforeCreate() {
            let {required} = ruleBuilder;
            this.rules = {
                ruKuDanId: [], // 入库单id
                ruKuLX: [], // 入库类型
                ruKuSJ: [], // 入库时间
                shangPinMC: [], // 商品名称
                shangPinId: [], // 商品id
                shangPinHuoHao: [], // 商品货号
                shangPinTiaoMa: [], // 商品条码
                shangPinJinJia: [], // 商品进价
                ruKuSL: [], // 入库数量
                ruKuJinE: [], // 入库金额
                gongHuoShang: [], // 供货商
                gongHuoShangMC: [], // 供货商名称
                shangPinDW: [], // 商品单位
                shengChanRQ: [], // 生产日期
                baoZhiQiTian: [], // 保质期（天）
                guoQiRQ: [], // 过期日期
                ruKuCangKuId: [], // 入库仓库id
                shiFuDuoGuiGe: [], // 是否多规格
                guiGeHuoHao: [], // 规格货号
                guiGeTiaoMa: [], // 规格条码
                guiGeShangPinJinJia: [], // 规格商品进价
                shiFuShanChu: [], // 是否删除
            }
            this.titlePrefix = '入库记录';
            this.defaultForm = {
                id: null,
                ruKuDanId: "", // 入库单id
                ruKuLX: "", // 入库类型
                ruKuSJ: "", // 入库时间
                shangPinMC: "", // 商品名称
                shangPinId: "", // 商品id
                shangPinHuoHao: "", // 商品货号
                shangPinTiaoMa: "", // 商品条码
                shangPinJinJia: "", // 商品进价
                ruKuSL: "", // 入库数量
                ruKuJinE: "", // 入库金额
                gongHuoShang: "", // 供货商
                gongHuoShangMC: "", // 供货商名称
                shangPinDW: "", // 商品单位
                shengChanRQ: "", // 生产日期
                baoZhiQiTian: "", // 保质期（天）
                guoQiRQ: "", // 过期日期
                ruKuCangKuId: "", // 入库仓库id
                shiFuDuoGuiGe: "", // 是否多规格
                guiGeHuoHao: "", // 规格货号
                guiGeTiaoMa: "", // 规格条码
                guiGeShangPinJinJia: "", // 规格商品进价
                shiFuShanChu: "", // 是否删除
            };
            this.addService = add;
            this.updateService = update;
            this.getUpdateService = getUpdate;
        }
    }
</script>

<style scoped>

</style>