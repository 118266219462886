<template>
    <el-dialog :title="title" :visible.sync="editor.visible" @open="open" :close-on-click-modal="false"
               destroy-on-close center append-to-body width="1000px">
        <el-form ref="form" :model="form" :rules="rules" class="x-edit">
            <x-divider title="基本信息"></x-divider>
            <el-form-item label="操作人" v-show="false" prop="caoZuoRen">
                <el-input v-model="form.caoZuoRen" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="入库类型" prop="ruKuLX" v-show="false">
                {{form.ruKuLX}}
            </el-form-item>
            <el-form-item label="入库时间" prop="ruKuSJ" v-show="false">
                <el-date-picker v-model="form.ruKuSJ" size="small" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"/>
            </el-form-item>
            <el-form-item label="补货备注" prop="beiZhu">
                <el-input v-model="form.beiZhu" size="small" maxlength="64" show-word-limit/>
            </el-form-item>
            <x-divider title="入库信息明细"></x-divider>
        </el-form>
        <el-button @click="isShowAddList = true">添加</el-button>

        <el-table
                ref="multipleTable"
                :data="form.ruKuXinXiMXs"
                tooltip-effect="dark"
                style="width: 100%"
        >
            <el-table-column label="操作" width="100">
                <template slot-scope="scope">
                    <el-button type="danger" round size="small" @click="removeRow(scope.row)">删除</el-button>
                </template>
            </el-table-column>
            <el-table-column width="150" prop="caiGouDanID" label="采购单ID" show-overflow-tooltip></el-table-column>
            <el-table-column width="150" prop="shangPinMC" label="商品名称" show-overflow-tooltip></el-table-column>
            <el-table-column width="150" prop="guiGe" label="规格" show-overflow-tooltip></el-table-column>
            <el-table-column width="150" prop="huoHao" label="货号" show-overflow-tooltip></el-table-column>
            <el-table-column width="150" prop="tiaoMa" label="条码" show-overflow-tooltip></el-table-column>
            <el-table-column width="150" prop="danWei" label="单位" show-overflow-tooltip></el-table-column>
            <el-table-column label="可入库数量" width="150" prop="weiRuKuSL"></el-table-column>
            <el-table-column width="150" prop="ruKuJia" label="进货价" show-overflow-tooltip></el-table-column>
            <el-table-column label="入库数量" width="150">
                <template slot-scope="scope">
                    <el-input-number
                            v-model="scope.row.ruKuSL"
                            size="small"
                    ></el-input-number>
                </template>
            </el-table-column>
            <el-table-column width="150" prop="beiZhu" label="备注" show-overflow-tooltip>
                <template slot-scope="scope">
                    <el-input v-model="scope.row.beiZhu" size="small"></el-input>
                </template>
            </el-table-column>


        </el-table>


        <el-dialog append-to-body title="添加" :visible.sync="isShowAddList" width="50%">
            <el-table
                    ref="multipleTable"
                    :data="cGDXQOptions"
                    tooltip-effect="dark"
                    style="width: 100%"
                    @selection-change="handleSelectionChange"
            ><!--这里是查询的列表-->
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column width="150" prop="caiGouDanID" label="采购单ID" show-overflow-tooltip></el-table-column>
                <el-table-column width="150" prop="shangPinMC" label="商品名称" show-overflow-tooltip></el-table-column>
                <el-table-column width="150" prop="guiGe" label="规格" show-overflow-tooltip></el-table-column>
                <el-table-column width="150" prop="huoHao" label="货号" show-overflow-tooltip></el-table-column>
                <el-table-column width="150" prop="tiaoMa" label="条码" show-overflow-tooltip></el-table-column>
                <el-table-column width="150" prop="danWei" label="单位" show-overflow-tooltip></el-table-column>
                <el-table-column width="150" prop="jinHuoJia" label="进货价" show-overflow-tooltip></el-table-column>
            </el-table>

            <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="saveChoose" icon="el-icon-document-add">选 择</el-button>
            <el-button @click="cancelChoose" icon="el-icon-switch-button">取 消</el-button>
        </span>
        </el-dialog>


        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="save(saveHandle)" icon="el-icon-document-add">保 存</el-button>
            <el-button @click="cancel" icon="el-icon-switch-button">取 消</el-button>
        </span>

    </el-dialog>
</template>

<script>
    import {add, update, getUpdate} from "@/service/hlgl/RuKuXX";
    import XEditBase from "@/components/x/XEditBase";
    import {ruleBuilder} from '@/util/validate';
    import {getCangKuList} from "@/service/cggl/TuiHuoDan";
    import {getCaiGouDanXiangQingList} from "@/service/cggl/CaiGouDanXiangQing";
    import {TYPE_RU_KU_L_X} from "@/util/constant";
    import {deepCopy, deepMerge} from "@/util/objects"

    export default {
        name: "BuHuoEdit",
        mixins: [XEditBase],
        beforeCreate() {
            let {required} = ruleBuilder;
            this.rules = {
                ruKuDanID: [], // 入库单ID
                ruKuShangPinZongShu: [], // 入库商品总数
                ruKuJinE: [], // 入库金额
                ruKuLX: [], // 入库类型
                ruKuCangKu: [], // 入库仓库
                shiShouShangPinZongShu: [], // 实收商品总数
                shiShouJinE: [], // 实收金额
                caoZuoRen: [], // 操作人
                caoZuoRenID: [], // 操作人ID
                ruKuSJ: [], // 入库时间
                beiZhu: [], // 备注
            }
            this.titlePrefix = '入库信息';
            this.ruKuXinXiMXsModel = {
                ruKuDanID: "", // 入库单ID
                shangPinMC: "", // 商品名称
                shangPinID: "", // 商品ID
                shangPinHuoHao: "",//商品货号
                gongHuoShang: "",
                gongHuoShangID: "",
                guiGe: "", // 规格
                tiaoMa: "", // 条码
                huoHao: "", // 货号
                danWei: "", // 单位
                shengChanRQ: "", // 生产日期
                baoZhiQiTian: "", // 保质期（天）
                ruKuSL: "", // 入库数量
                ruKuJia: "", // 入库价
                ruKuJinEXiaoJi: "", // 入库金额小计
                beiZhu: "", // 备注
            }
            this.defaultForm = {
                id: null,
                ruKuDanID: "", // 入库单ID
                ruKuShangPinZongShu: "", // 入库商品总数
                ruKuJinE: "", // 入库金额
                ruKuLX: TYPE_RU_KU_L_X.BHRK.key, // 入库类型限定为补货入库
                gongHuoShang: "",
                gongHuoShangID: "",
                ruKuCangKu: "", // 入库仓库
                shiShouShangPinZongShu: "", // 实收商品总数
                shiShouJinE: "", // 实收金额
                caoZuoRenID: this.$store.getters.user.id, // 操作人ID
                caoZuoRen: this.$store.getters.user.name, // 操作人
                ruKuSJ: "", // 入库时间
                beiZhu: "", // 备注
                ruKuXinXiMXs: [],
            };
            this.addService = add;
            this.updateService = update;
            this.getUpdateService = getUpdate;
        },
        data() {
            return {
                rkFS:TYPE_RU_KU_L_X,
                options: [],
                cGDXQOptions: [],
                gongHuoShangOptions: [],
                chooseTable: [],
                isShowAddList: false,
                kuCunZhuangKuang:{}
            }
        },
        mounted() {

        },
        methods: {
            open(){
                this.getCKList()
                this.getCGDXQList()
                this.form = deepCopy(this.defaultForm);
                //清空校验
                this._clearValidate();
                return Promise.resolve({code: 200});
            },
            // 获取采购单详情列表
            getCGDXQList() {
                getCaiGouDanXiangQingList(this.kuCunZhuangKuang.id).then((response) => {
                    console.log("这是返回过来的数据：",response.data)
                    this.cGDXQOptions = response.data
                    this.cGDXQOptions.forEach(item=>{
                        item.ruKuJia = item.jinHuoJia
                        item.caiGouDanXiangQingID = item.id
                    })
                    // this.cGDXQOptions = response.data.map(item => {
                    //     item.ruKuJia = item.jinHuoJia
                    //     item.caiGouDanXiangQingID = item.id
                    //     item.ruKuSL = item.yiRuKuSL
                    //     item.beiZhu = ''
                    //     //在这里矫正字段名， 和后端要的对上
                    //     //后面的字段是查出来的
                    //     // 如果返回的不对  就要在这里矫正
                    //     // 假如后端传回来的单位，是   shangPinDanWei
                    //     // item.danWei = item.shangPinDanWei        // 这样就好了
                    //     delete item.id
                    //     return item
                    // })
                })
            },
            maxReturnQuantity(row) {
                // 获取采购数量、已入库数量和已退货数量
                const caiGouSL = row.caiGouSL || 0;
                const yiRuKuSL = row.yiRuKuSL || 0;
                const yiTuiHuoSL = row.yiTuiHuoSL || 0;
                // 计算可入库数量的上限
                return caiGouSL - yiRuKuSL - yiTuiHuoSL;
            },
            // 获取仓库下拉选择列表
            getCKList() {
                getCangKuList().then((response) => {
                    this.options = response.data
                })
            },
            handleSelectionChange(val) {
                this.chooseTable = val;
            },
            saveChoose() {
                this.form.ruKuXinXiMXs = this.chooseTable
                this.isShowAddList = false
            },
            cancelChoose() {
                this.isShowAddList = false
                this.chooseTable = [...this.form.ruKuXinXiMXs]
            },
            removeRow(row) {
                this.form.ruKuXinXiMXs = this.form.ruKuXinXiMXs.filter(item => item !== row)
            },

            saveHandle() {
                this.chooseTable = []
                this.form.ruKuXinXiMXs = []
                this.getCGDXQList()
            },
            async save(callback) { // 通用save方法 增加回调
                this.loading = true;
                let isvalidate = false;
                try {
                    await this.$refs.form.validate((valid, message) => {
                        if (valid) { // 如果校验通过则提交
                            isvalidate = true;
                        } else { // 校验不通过
                            this.$message.error("有必填项没有填写请检查！");
                            this.loading = false;
                        }
                    });
                } catch (e) {
                    this.loading = false;
                    return;
                }
                if (!isvalidate) return;
                let api = this.addService;
                const response = await api(this.form).finally(() => {
                    this.loading = false; //TODO 更好的处理保存按钮加载问题
                });//执行保存方法
                if (callback && typeof callback === 'function') {
                    callback(response);
                }
                //打印提示信息
                this.$message.success(response.msg);
                this.$emit("submitted", response);
                this.cancel();
            },
        }
    }
</script>

<style scoped>

</style>
