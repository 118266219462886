<template>
    <div class="x-page-container" ref="resize">
        <!-- 查询区 -->
        <div class="x-page-search">
            <x-search-item label="商品货号">
                <el-input v-model="search.shangPinHuoHao" size="small"/>
            </x-search-item>
            <x-search-item label="商品名称">
                <el-input v-model="search.shangPinMC" size="small"/>
            </x-search-item>
            <x-search-item label="上下架状态">
                <el-input v-model="search.shangXiaJiaZT" size="small"/>
            </x-search-item>
            <div class="x-search-item">
                <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                <el-button type="primary" size="mini" @click="handleClear">清空</el-button>
            </div>
        </div>        <!-- 批量按钮区 -->
        <!-- 列表区域 -->
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border
                  @selection-change="handleSelectionChange">
            <el-table-column label="序号" type="index"/>
            <el-table-column  prop="shangPinMC" label="商品名称" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{scope.row.shiFuDuoGuiGe=="true"?scope.row.shangPinMC + '(' + scope.row.ciJiGuiGeMC + ')':scope.row.shangPinMC}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="shangPinHuoHao" label="货号" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{scope.row.shiFuDuoGuiGe=="true"?scope.row.guiGeHuoHao:scope.row.shangPinHuoHao}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="shangPinTiaoMa" label="条码" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{scope.row.shiFuDuoGuiGe=="true"?scope.row.guiGeTiaoMa:scope.row.shangPinTiaoMa}}</span>
                </template>
            </el-table-column>
            <el-table-column width="100" prop="shangPinJinJia" label="商品进价">
                <template slot-scope="scope">
                    <span>{{scope.row.shiFuDuoGuiGe=="true"?scope.row.guiGeShangPinJinJia:scope.row.shangPinJinJia}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="shangPinDW" label="商品单位" show-overflow-tooltip/>
            <el-table-column prop="shiJiKuCun" label="供应库存" show-overflow-tooltip/>
            <el-table-column prop="kuCunJinE" label="库存金额" show-overflow-tooltip/>
            <el-table-column prop="kuCunZT" label="库存状态">
                <x-dict-show slot-scope="{row}" :code="row.kuCunZT" dictType="T_KU_CUN_STATUS"/>
            </el-table-column>
            <el-table-column prop="shangPinZT" label="上下架状态">
                <x-dict-show slot-scope="{row}" :code="row.shangPinZT" dictType="T_SHANG_PIN_STATUS"/>
            </el-table-column>
            <el-table-column prop="beiZhu" label="备注"/>
            <el-table-column width="350" align="center" label="操作" fixed="right">
                <template slot-scope="{ row, $index }">
                    <el-button type="success" size="mini" round @click="BuHuoShow(row)">补货</el-button>
                    <el-button type="success" size="mini" round @click="RuKuJiLuXiangShow(row.id)">入库记录</el-button>
                    <el-button type="success" size="mini" round @click="ChuKuJiLuXiangShow(row.id)">出库记录</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination background
                       :total="table.total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, sizes, prev, pager, next, jumper"/>
        <!-- 编辑页区域 -->
        <bu-huo-edit ref="buHuoEdit" :editor="bhEditor" @submitted="refresh"/>
        <RuKuJiLuXiangQing ref="ruKuJiLuXiangQing"/>
        <ChuKuJiLuXiangQing ref="chuKuJiLuXiangQing"/>
    </div>
</template>
<script>
    import * as service from "@/service/kcgl/KuCunZhuangKuang";
    import XTableBase from "@/components/x/XTableBase";
    import RuKuJiLuXiangQing from "./RuKuJiLuXiangQing";
    import ChuKuJiLuXiangQing from "./ChuKuJiLuXiangQing";
    import BuHuoEdit from "./BuHuoEdit";

    export default {
        name: "KuCunZhuangKuangList",
        mixins: [XTableBase],
        components: {RuKuJiLuXiangQing, ChuKuJiLuXiangQing, BuHuoEdit},
        data() {
            this.refreshService = service.list;
            this.service = service;
            return {
                search: {
                    // cangKuBH: '',
                    // cangKuMC: '',
                    shangPinId: '',
                    shangPinHuoHao: '',
                    shangPinMC: '',
                    shangXiaJiaZT: '',
                },
                bhEditor: {
                    visible: false,
                    id: null,
                    isUpdate: false
                }
            }
        },
        methods: {
            BuHuoShow(row) {
                this.bhEditor.isUpdate = false;
                this.bhEditor.id = null;
                this.bhEditor.visible = true;
                this.$refs.buHuoEdit.kuCunZhuangKuang = row
            },
            RuKuJiLuXiangShow(id) {
                let shangPinId = id;
                this.$refs.ruKuJiLuXiangQing.showxx(shangPinId);
                console.log(shangPinId)
            },
            ChuKuJiLuXiangShow(id) {
                let shangPinId = id;
                this.$refs.chuKuJiLuXiangQing.show(shangPinId);
                console.log(shangPinId)
            },
        }
    }
</script>

<style scoped>
</style>
