<template>
    <div>
        <el-dialog title="出库记录" :visible.sync="xiangQingVisible" width="80%" >
            <!-- 列表区域 -->
            <el-table :data="xqxx" style="width: 100%">
                <el-table-column width="50" label="序号" type="index" fixed/>
                <el-table-column width="180" prop="shangPinId" label="商品id" />
                <!--            <el-table-column width="180" prop="ruKuDanId" label="入库单id" />-->
                <el-table-column width="180" prop="shangPinMC" label="商品名称" />
                <el-table-column width="180" prop="chuKuLX" label="出库类型" >
                    <x-dict-show slot-scope="{row}" :code="row.chuKuLX" dictType="T_CHU_KU_LX" />
                </el-table-column>
                <el-table-column width="180" prop="chuKuSJ" label="出库时间" />
                <el-table-column width="180" prop="shangPinHuoHao" label="商品货号" />
                <el-table-column width="180" prop="shangPinTiaoMa" label="商品条码" />
                <el-table-column width="180" prop="chengBenJia" label="成本价" />
                <el-table-column width="180" prop="chuKuJia" label="出库价" />
                <el-table-column width="180" prop="kuCunSL" label="库存数量" />
                <el-table-column width="180" prop="chuKuSL" label="出库数量" />
               <!-- <el-table-column width="180" prop="gongHuoShang" label="供货商" />-->
                <el-table-column width="180" prop="gongHuoShangMC" label="供货商名称" />
                <el-table-column width="180" prop="chuKuJinE" label="出库金额" />
                <el-table-column width="180" prop="shangPinDW" label="商品单位" />
                <el-table-column width="180" prop="shengChanRQ" label="生产日期" />
                <el-table-column width="180" prop="baoZhiQiTian" label="保质期（天）" />
                <el-table-column width="180" prop="guoQiRQ" label="过期日期" />
                <el-table-column width="180" prop="chuKuCangKu" label="出库仓库" />
                <el-table-column width="180" prop="shiFuDuoGuiGe" label="是否多规格" >
                    <x-dict-show slot-scope="{row}" :code="row.shiFuDuoGuiGe" dictType="T_YES_OR_NO" />
                </el-table-column>
                <el-table-column width="180" prop="guiGeHuoHao" label="规格货号" />
                <el-table-column width="180" prop="guiGeTiaoMa" label="规格条码" />
                <el-table-column width="180" prop="guiGeShangPinChengBenJia" label="规格商品成本价" />
                <el-table-column width="180" prop="beiZhu" label="备注" />
            </el-table>
            <!-- 分页区域 -->
            <el-pagination background
                           :total="table.total"
                           @size-change="handleSizeChange"
                           @current-change="handleCurrentChange"
                           layout="total, sizes, prev, pager, next, jumper"/>
        </el-dialog>

    </div>
</template>
<script>
    import * as service from "@/service/kcgl/ChuKuJiLu";
    import XTableBase from "@/components/x/XTableBase";
    import Edit from "@/view/kcgl/ckjl/ChuKuJiLuEdit";
    import Detail from "@/view/kcgl/ckjl/ChuKuJiLuDetail";

    export default {
        name: "ChuKuJiLuList",
        mixins: [XTableBase],
        components: {Edit, Detail},
        data() {
            this.refreshService = service.list;
            this.service = service;
            return {
                xiangQingVisible: false,
                xqxx:[
                    {
                        shangPinId:"",
                        shangPinMC:"",
                        chuKuLX:"",
                        chuKuSJ:"",
                        shangPinHuoHao:"",
                        shangPinTiaoMa:"",
                        chengBenJia:"",
                        chuKuJia:"",
                        kuCunSL:"",
                        chuKuSL:"",
                        chuKuJinE:"",
                        gongHuoShang:"",
                        gongHuoShangMC:"",
                        shangPinDW:"",
                        shengChanRQ:"",
                        baoZhiQiTian:"",
                        guoQiRQ:"",
                        chuKuCangKu:"",
                        shiFuDuoGuiGe:"",
                        guiGeHuoHao:"",
                        guiGeTiaoMa:"",
                        guiGeShangPinChengBenJia:"",
                        beiZhu:"",
                    }
                ],
            }
        },
        methods:{
            show(shangPinId){
                this.xiangQingVisible=true;
                service.getChuKuJiLuByShangPinId(shangPinId).then(res=>{
                    if (res.code===200){
                        this.xqxx = res.data;
                    }
                })
            }
        }
    }
</script>

<style scoped>
</style>
