<template>
    <div>
        <el-dialog title="入库记录" :visible.sync="xiangQingVisible" width="80%" >
            <!-- 列表区域 -->
            <el-table :data="xqxx" style="width: 100%">
                <el-table-column width="50" label="序号" type="index" fixed/>
                <el-table-column width="180" prop="shangPinId" label="商品id" />
                <!--            <el-table-column width="180" prop="ruKuDanId" label="入库单id" />-->
                <el-table-column width="180" prop="shangPinMC" label="商品名称" />
                <el-table-column width="180" prop="ruKuLX" label="入库类型" >
                    <x-dict-show slot-scope="{row}" :code="row.ruKuLX" dictType="T_RU_KU_L_X" />
                </el-table-column>
                <el-table-column width="180" prop="ruKuSJ" label="入库时间" />
                <el-table-column width="180" prop="shangPinHuoHao" label="商品货号" />
                <el-table-column width="180" prop="shangPinTiaoMa" label="商品条码" />
                <el-table-column width="180" prop="shangPinJinJia" label="商品进价" />
                <el-table-column width="180" prop="ruKuSL" label="入库数量" />
                <el-table-column width="180" prop="ruKuJinE" label="入库金额" />
                <el-table-column width="180" prop="shangPinDW" label="商品单位" />
                <el-table-column width="180" prop="shengChanRQ" label="生产日期" />
                <el-table-column width="180" prop="baoZhiQiTian" label="保质期（天）" />
                <!--            <el-table-column width="180" prop="ruKuCangKuId" label="入库仓库id" />-->
                <el-table-column width="180" prop="shiFuDuoGuiGe" label="是否多规格" >
                    <x-dict-show slot-scope="{row}" :code="row.shiFuDuoGuiGe" dictType="T_YES_OR_NO" />
                </el-table-column>
                <el-table-column width="180" prop="guiGeHuoHao" label="规格货号" />
                <el-table-column width="180" prop="guiGeTiaoMa" label="规格条码" />
                <el-table-column width="180" prop="guiGeShangPinJinJia" label="规格商品进价" />
            </el-table>
            <!-- 分页区域 -->
            <el-pagination background
                           :total="table.total"
                           @size-change="handleSizeChange"
                           @current-change="handleCurrentChange"
                           layout="total, sizes, prev, pager, next, jumper"/>
        </el-dialog>

    </div>
</template>
<script>
    import * as service from "@/service/kcgl/RuKuJiLu";
    import XTableBase from "@/components/x/XTableBase";
    import Edit from "@/view/kcgl/rkjl/RuKuJiLuEdit";
    import Detail from "@/view/kcgl/rkjl/RuKuJiLuDetail";

    export default {
        name: "RuKuJiLuList",
        mixins: [XTableBase],
        components: {Edit, Detail},
        data() {
            this.refreshService = service.list;
            this.service = service;
            return {
                xiangQingVisible: false,
                xqxx:[
                    {
                        shangPinId:"",
                        shangPinMC:"",
                        ruKuLX:"",
                        ruKuSJ:"",
                        shangPinHuoHao:"",
                        shangPinTiaoMa:"",
                        shangPinJinJia:"",
                        ruKuSL:"",
                        ruKuJinE:"",
                        shangPinDW:"",
                        shengChanRQ:"",
                        baoZhiQiTian:"",
                        shiFuDuoGuiGe:"",
                        guiGeHuoHao:"",
                        guiGeTiaoMa:"",
                        guiGeShangPinJinJia:"",
                    }
                ],
                shangPinId: '',
            }
        },
        methods:{
            showxx(shangPinId){
                this.xiangQingVisible=true;
                service.getRuKuJiLuByShangPinId(shangPinId).then(res=>{
                    if (res.code===200){
                        this.xqxx = res.data;
                    }
                })
            }
        }
    }
</script>

<style scoped>
</style>
